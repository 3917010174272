const assets = {
  logo: require('./images/logo.png'),
  slogan: require('./images/slogan.png'),
  banner: require('./images/banner.png'),
  about_bg: require('./images/about_bg.png'),
  beauty: require('./images/beauty.png'),
  community_person: require('./images/community_person.png'),
  community1: require('./images/community1.png'),
  community2: require('./images/community2.png'),
  community3: require('./images/community3.png'),
  community4: require('./images/community4.png'),
  fav: require('./images/fav.png'),
  mall_bg:require('./images/mall_bg.png'),
  contact:require('./images/contact.png'),
  phone:require('./images/phone.png'),
  download:require('./images/download.png'),
  safe:require('./images/公安备案图标.png'),
  gbds:require('./images/gbds.jpg'),
  wwjyxk:require('./images/wwjyxk.jpg'),
  yaopin:require('./images/yaopin.png')
}

export default assets
